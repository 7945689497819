import React from "react";

const Rules_and_regulations = () => {
  return (
    <>
      <div className="container">
        <h1
          className="headingTitleNormal display-3"
          style={{ padding: "20px 0px" }}
        >
          <span className="headingTitleGradient">{"Rules And "}</span>
          {"Regulations"}
        </h1>
        <ul>
          <li>
            MTPL Classes reserve the right to deny Admission to a students
            without giving any reason.
          </li>
          <li>
            Students are not allowed to miss classes and all student are
            expected to progress satisfactorily. Any students whose record falls
            below the minimum acceptable standard,will ask ti withdraw from the
            institute in such case fees paid will not be refunded.
          </li>
          <li>
            Any attempt to copy our study material by any electronic, mechanical
            or any other retrieval device would be treated as an offence aas we
            reserve all copyrights privileges of these materials.Any attempt
            made by a student will lead to termination from the institute
            without refunding fees. Our decision in such case is final.
          </li>
          <li>
            We have power to make addition and alternations the rules, working
            hours and time table deemed necessary from time to time without any
            prior information.
          </li>
          <li>
            In Matters not covered in above rules and regulation, the decision
            of MTPL classes directors shall be final and binding to student and
            his/her guardian.{" "}
          </li>
          <li>All disputes are subjected to chhindwara Jurisdiction only.</li>
          <li>
            Students getting rank in competitive examination will be the result
            of strenuous effort of MTPL & not of any individual effort,
            institute have rights to published students photograph in leading
            newspapers.
          </li>
        </ul>
      </div>
    </>
  );
};

export default Rules_and_regulations;
